const asianCountries = ['AFG', 'ARM', 'AZE', 'BHR', 'BGD',
  'BTN', 'BRN', 'KHM', 'CHN', 'CXR', 'CCK', 'IOT', 'GEO',
  'HKG', 'IND', 'IDN', 'IRN', 'IRQ', 'ISR', 'JPN', 'JOR',
  'KAZ', 'KWT', 'KGZ', 'LAO', 'LBN', 'MAC', 'MYS', 'MDV',
  'MNG', 'MMR', 'NPL', 'PRK', 'OMN', 'PAK', 'PSE', 'PHL',
  'QAT', 'SAU', 'SGP', 'KOR', 'LKA', 'SYR', 'TWN', 'TJK',
  'THA', 'TUR', 'TKM', 'ARE', 'UZB', 'VNM', 'YEM'];

const southAmericanCountries = ['ARG', 'BOL', 'BRA', 'CHL', 'COL',
  'ECU', 'FLK', 'GUF', 'GUY', 'PRY', 'PER', 'SUR', 'URY', 'VEN'];

const europeanCountries = ['ALB', 'AND', 'AUT', 'BLR', 'BEL',
  'BIH', 'BGR', 'HRV', 'CYP', 'CZE', 'DNK', 'EST', 'FRO',
  'FIN', 'FRA', 'DEU', 'GIB', 'GRC', 'HUN', 'ISL', 'IRL',
  'IMN', 'ITA', 'XKX', 'LVA', 'LIE', 'LTU', 'LUX', 'MKD',
  'MLT', 'MDA', 'MCO', 'MNE', 'NLD', 'NOR', 'POL', 'PRT',
  'ROU', 'RUS', 'SMR', 'SRB', 'SVK', 'SVN', 'ESP', 'SWE',
  'CHE', 'UKR', 'GBR', 'VAT', 'RSB'];

export const Geo = Object.freeze({
  ASIA: 'ASIA',
  EM: 'EM',
  EUROPE: 'EUROPE',
  ILS: 'ILS',
  JPN: 'JPN',
  NA: 'NA',
  RUB: 'RUB',
  SA: 'SA',
});

export const toGeo = (store) => {
  const iso = store.address.country;
  const { region } = store;
  switch (iso) {
    case 'ILS':
      return Geo.ILS;
    case 'JPN':
      return Geo.JPN;
    case 'RUB':
      return Geo.RUB;
    default:
      switch (region) {
        case 'NORTH_AMERICA':
          return Geo.NA;
        case 'GREATER_CHINA':
          return Geo.ASIA;
        case 'EMERGING_MARKETS':
          return Geo.EM;
        case 'EUROPE_MIDDLE_EAST_AFRICA':
        case 'ASIA_PACIFIC_LATIN_AMERICA':
        default:
          if (europeanCountries.includes(iso)) {
            return Geo.EUROPE;
          } else if (southAmericanCountries.includes(iso)) {
            return Geo.SA;
          } else if (asianCountries.includes(iso)) {
            return Geo.ASIA;
          } else {
            return Geo.EM;
          }
      }
  }
};
