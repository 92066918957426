import PropTypes from 'prop-types';
import React from 'react';

import { scsPermissions } from '../../../utils/tab-permissions';
import { PageTemplate } from '../../reusable';

import Main from './Main';

const SCSBulkEdit = (props) => (
  <PageTemplate
    auth={scsPermissions}
    description="Bulk Edit SCS Configs."
    help={(
      <a
        className="ncss-cta-primary-dark underline text-color-secondary"
        href="https://confluence.nike.com/display/RCFITC/RCF+KB+-+SCS+UI"
        rel="noopener noreferrer"
        target="_blank"
      >
        Click here to view the KB
      </a>
    )}
    page={<Main />}
    path={props.location.pathname}
    title="Store Config Service"
  />
);

SCSBulkEdit.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
};

export default SCSBulkEdit;
