import PropTypes from 'prop-types';
import React from 'react';

import { rosPermissions } from '../utils/tab-permissions';

import { PageTemplate } from './reusable';

const helpFooter = () => (
  <footer className="ta-sm-l">
    <p className="headline-4">Encountering an issue?</p>
    <p className="body-2">Please create a&nbsp;
      <a
        className="ncss-cta-primary-dark underline text-color-secondary"
        href="https://niketech.service-now.com/nav_to.do?uri=%2Fincident.do%3Fsys_id%3D-1%26sys_is_list%3Dtrue%26sys_target%3Dincident%26sysparm_checked_items%3D%26sysparm_fixed_query%3D%26sysparm_group_sort%3D%26sysparm_list_css%3D%26sysparm_query%3Dactive%3Dtrue%5Eassignment_group%3Ddd90bf891b240090ee09fd115b4bcbbc%26sysparm_referring_url%3Dincident_list.do%3Fsysparm_query%3Dactive%253Dtrue%255Eassignment_group%253Ddd90bf891b240090ee09fd115b4bcbbc@99@sysparm_first_row%3D1%26sysparm_target%3D%26sysparm_view%3D%26sysparm_view_forced%3Dtrue"
        rel="noopener noreferrer"
        target="_blank"
      >
        ServiceNow
      </a>
      &nbsp;ticket and assign it to <span className="text-color-accent">Retail-ROS</span>.
    </p>
    <p className="headline-4 mt4-sm">Questions?</p>
    <p className="body-2">If the &quot;Need Help?&quot; button in the top right corner and linked Confluence KB do not answer your questions, then please contact Retail Commerce Foundation at Lst-Retail.RCF.Warriors.Devs@nike.com or on Slack at<span> </span>
      <a
        className="ncss-cta-primary-dark underline text-color-secondary"
        href="https://nike.enterprise.slack.com/archives/C01TARWMVRQ"
        rel="noopener noreferrer"
        target="_blank"
      >
        #rcf-support
      </a>. Thank you!
    </p>
  </footer>
);

const Home = ({ location }) => (
  <main className="ncss-row ta-sm-c">
    <PageTemplate
      auth={rosPermissions}
      description="Please navigate through the app via the sidebar to the left."
      help={<footer className="ncss-col-sm-8">{helpFooter()}</footer>}
      page={(
        <section className="ncss-col-sm-8 mb12-sm">
          <article className="mt12-sm mb12-sm" />
          <footer>{helpFooter()}</footer>
        </section>
        )}
      path={location.pathname}
      title="Welcome to Retail Operations!"
    />
  </main>
  );

Home.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
};

export default Home;
