/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { Close } from '@nike/nike-design-system-icons';
import countries from 'i18n-iso-countries';
import PropTypes, { object } from 'prop-types';
import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';

import './bulkedit.css';

import {
    ButtonBlack, ButtonWhite,
} from '../../reusable';
import { fetchFilteredStores, getFilterOptions } from '../SCSFilter';

const requiredRed = '#d43f21';

const FilterOverlay = ({
    isFilterOverlayVisible, onClose, stores, onFilterUpdate, filterApplied, clearAllFilters, filteringCondition, onFilteringConditionChange, updateFilterRef
}) => {
    const [currentFilteringCondition, setFilteringCondition] = useState(filteringCondition);
    const [allStores, setAllStores] = useState([]);

    useEffect(() => {
        setAllStores(stores);
    }, [stores]);

    const clearFilters = () => setFilteringCondition({
        country: { label: 'All Countries', value: '' },
        facilityType: { label: 'All Facility Types', value: '' },
        region: { label: 'Select a Region', value: '' },
        regionSelected: false,
        search: '',
        state: { label: 'All States', value: '' },
        storeStatus: { label: 'All Store Statuses', value: '' },
    });

    const clearAllFiltersFunc = () => {
        clearFilters();
        clearAllFilters();
    };

    const handleOnClose = () => {
        onClose();
    };

    const applyFilterFunc = async (filteredStores) => {
        onFilterUpdate(filteredStores);
    };

    const updateFilter = async (filterType, value) => {
        const newFilteringCondition = { ...currentFilteringCondition, [filterType]: { label: value.label, value: value.value } };
        setFilteringCondition(newFilteringCondition);
        filterApplied();
    };

    useEffect(() => {
        if (updateFilterRef) {
          updateFilterRef.current = updateFilter;
        }
      }, [updateFilterRef]);

    useEffect(() => {
        const filteredStores = fetchFilteredStores(currentFilteringCondition, allStores);
        applyFilterFunc(filteredStores);
        onFilteringConditionChange(currentFilteringCondition);
    }, [currentFilteringCondition]);

    if (!isFilterOverlayVisible) return null;

    const filters = [
        {
            accessor: 'region',
            changeValue: (value) => {
                updateFilter('region', value);
                localStorage.setItem('ros-stores-region', value.label);
            },
            getLabel: (code) => code,
            id: 'regionFilter',
            isDisabled: false,
            label: 'All Regions',
            option: filteringCondition.region,
        },
        {
            accessor: 'address.country',
            changeValue: (value) => updateFilter('country', value),
            getLabel: (code) => (code === 'TUR' ? 'Türkiye' : countries.getName(code, 'en')),
            id: 'countryFilter',
            isDisabled: stores.length === 0,
            label: 'All Countries',
            option: filteringCondition.country,
        },
        {
            accessor: 'address.state',
            changeValue: (value) => updateFilter('state', value),
            getLabel: (code) => code,
            id: 'stateFilter',
            isDisabled: stores.length === 0,
            label: 'All States',
            option: filteringCondition.state,
        },
        {
            accessor: 'facilityType',
            changeValue: (value) => updateFilter('facilityType', value),
            getLabel: (code) => code,
            id: 'facilityTypeFilter',
            isDisabled: stores.length === 0,
            label: 'All Facility Types',
            option: filteringCondition.facilityType,
        },
        {
            accessor: 'storeStatus',
            changeValue: (value) => updateFilter('storeStatus', value),
            getLabel: (code) => code,
            id: 'storeStatusFilter',
            isDisabled: stores.length === 0,
            label: 'All Store Statuses',
            option: filteringCondition.storeStatus,
        },
    ];

    return (
      <div className="filter-overlay">
        <div className="filter-overlay-content">
          <div className="close-container">
            <Close name="Close" size="m" onClick={handleOnClose} />
          </div>
          <h1 className="headline-4">Filters</h1>
          {filters.map((filter) => (
            <article key={filter.id} className="ncss-col-sm-10 overlay-select" style={{ display: filter.disabled ? 'none' : '', justifyContent: 'center' }}>
              <ReactSelect
                classNamePrefix="react-select"
                id={filter.id}
                isDisabled={filter.isDisabled}
                options={getFilterOptions(fetchFilteredStores(filteringCondition, stores), filter)}
                styles={{
                                menu: (styles) => ({ ...styles, zIndex: 100 }),
                                singleValue: (styles) => ({ ...styles, color: filter.required ? requiredRed : styles.color }),
                            }}
                value={filter.option}
                onChange={(value) => filter.changeValue(value)}
              />
            </article>
                ))}
          <div className="button-container">
            <ButtonWhite label="Clear Filter" onClick={clearAllFiltersFunc} />
            <ButtonBlack label="Done" onClick={handleOnClose} />
          </div>
        </div>
      </div>
    );
};

FilterOverlay.propTypes = {
    clearAllFilters: PropTypes.func.isRequired,
    filterApplied: PropTypes.func.isRequired,
    filteringCondition: PropTypes.object.isRequired,
    isFilterOverlayVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onFilteringConditionChange: PropTypes.func.isRequired,
    onFilterUpdate: PropTypes.func.isRequired,
    stores: PropTypes.arrayOf(object).isRequired,
    updateFilterRef: PropTypes.object.isRequired,
};

export default FilterOverlay;
