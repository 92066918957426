import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import {
  businessConceptValues,
  brandValues,
  countryOptions,
  cmpCountries,
  facilityTypeValues,
} from '../../../../utils/static/sls-property-values';
import { CustomPanel, Select } from '../../../reusable';

import StoreNumber from './StoreNumber';

/* display form contents...
 * if adding
 * if there exists an error on any of the fields that we validate
 * note: we use !! to force consideration of prop as bool
 */
const calculateIsOpen = (properties) => (properties.adding
  || !!(properties.formErrors.address ? properties.formErrors.address.country : true) || !!properties.formErrors.storeNumber
  || !!properties.formErrors.name || !!properties.formErrors.description
  || !!properties.formErrors.facilityType || !!properties.formErrors.company
  || !!properties.formErrors.businessConcept || !!properties.formErrors.brand);

const cmpFilteredCountryOptions = countryOptions.map(
  (country) => (cmpCountries.includes(country.value) ? { ...country, isDisabled: true } : country),
);

const cmpFilteredBrandOptions = brandValues.map(
  (val) => (val === 'NIKE' ? { isDisabled: true, label: val, value: val } : { label: val, value: val }),
);

const facilityTypeOptions = facilityTypeValues.map((val) => ({ label: val, value: val }));
const cmpFilteredFacilityTypeOptions = facilityTypeValues
  .map((val) => (val === 'NIKE_OWNED_STORE' ? { isDisabled: true, label: val, value: val } : { label: val, value: val }));

class General extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: calculateIsOpen(props),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formErrors !== this.props.formErrors) {
      if (this.state.isOpen) { return; }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isOpen: calculateIsOpen(this.props) });
    }
  }

  panelToggle = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }));

  render = () => (
    <CustomPanel
      isOpen={this.state.isOpen}
      label="General"
      onClick={this.panelToggle}
    >
      <Select
        className="ncss-col-sm-6 va-sm-t"
        errorMessage={this.props.disableCmpFields ? '' : (this.props.formErrors.address?.country || '')}
        id="countrySelect"
        isDisabled={(!this.props.adding && !this.props.userIsOwner) || this.props.disableCmpFields}
        label="Country"
        options={[{ label: '', value: '' }]
          .concat(
            this.props.formData.facilityType === 'NIKE_OWNED_STORE' && this.props.formData.brand === 'NIKE' && this.props.adding
              ? cmpFilteredCountryOptions
              : countryOptions,
          )}
        value={this.props.formData.address?.country || ''}
        zIndex={21}
        onChange={this.props.updateCountry}
      />
      <StoreNumber
        adding={this.props.adding}
        disableCmpFields={this.props.disableCmpFields}
        errorMessage={!this.props.disableCmpFields ? '' : this.props.formErrors.storeNumber}
        history={this.props.history}
        storeNumber={this.props.formData.storeNumber || ''}
        stores={this.props.stores}
        updateStoreNumber={(value) => this.props.updateFormData('storeNumber', value)}
        userIsOwner={this.props.userIsOwner}
      />
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          errorMessage={!this.props.disableCmpFields && this.props.formErrors.name}
          isDisabled={this.props.userIsReadOnly || this.props.disableCmpFields}
          label="Name"
          value={this.props.formData.name || ''}
          onChange={({ target: { value } }) => this.props.updateFormData('name', value)}
        />
      </article>
      <article className="ncss-col-sm-6 va-sm-t">
        <Input
          errorMessage={this.props.formErrors.description}
          isDisabled={this.props.userIsReadOnly}
          label="Description"
          value={this.props.formData.description || ''}
          onChange={({ target: { value } }) => this.props.updateFormData('description', value)}
        />
      </article>
      <Select
        className="ncss-col-sm-6 va-sm-t"
        errorMessage={this.props.disableCmpFields ? '' : this.props.formErrors.facilityType}
        id="facilityType"
        isDisabled={this.props.userIsReadOnly || this.props.disableCmpFields}
        label="Facility Type"
        options={this.props.formData.brand === 'NIKE' && (cmpCountries.includes(this.props.formData.address?.country)) && this.props.adding ? cmpFilteredFacilityTypeOptions : facilityTypeOptions}
        value={this.props.formData.facilityType || ''}
        zIndex={20}
        onChange={(value) => this.props.updateFormData('facilityType', value)}
      />
      <Select
        className="ncss-col-sm-6 va-sm-t"
        errorMessage={this.props.disableCmpFields ? '' : this.props.formErrors.company}
        id="company"
        isDisabled={this.props.userIsReadOnly || this.props.disableCmpFields}
        label="Company"
        options={Object.keys(this.props.ccmConfig).length > 0 ? this.props.ccmConfig['sls.companyValues'].map((val) => ({ label: val, value: val })) : []}
        value={this.props.formData.company || ''}
        zIndex={19}
        onChange={(value) => this.props.updateFormData('company', value)}
      />
      <Select
        className="ncss-col-sm-4 va-sm-t"
        errorMessage={this.props.disableCmpFields ? '' : this.props.formErrors.businessConcept}
        id="businessConcept"
        isDisabled={this.props.userIsReadOnly || this.props.disableCmpFields}
        label="Business Concept"
        options={businessConceptValues.map((val) => ({ label: val, value: val }))}
        value={this.props.formData.businessConcept || ''}
        zIndex={18}
        onChange={(value) => this.props.updateFormData('businessConcept', value)}
      />
      <Select
        className="ncss-col-sm-4 va-sm-t"
        errorMessage={this.props.disableCmpFields ? '' : this.props.formErrors.storeConcept}
        id="storeConcept"
        isDisabled={this.props.userIsReadOnly || this.props.disableCmpFields}
        label="Store Concept"
        options={Object.keys(this.props.ccmConfig).length > 0 ? this.props.ccmConfig['sls.storeConceptValues'].map((val) => ({ label: val, value: val })) : []}
        value={this.props.formData.storeConcept || ''}
        zIndex={18}
        onChange={(value) => this.props.updateFormData('storeConcept', value)}
      />
      <Select
        className="ncss-col-sm-4 va-sm-t"
        errorMessage={this.props.disableCmpFields ? '' : this.props.formErrors.brand}
        id="brand"
        isDisabled={this.props.userIsReadOnly || this.props.disableCmpFields}
        label="Brand"
        options={(this.props.formData.facilityType === 'NIKE_OWNED_STORE' && cmpCountries.includes(this.props.formData.address?.country))
          ? cmpFilteredBrandOptions
          : brandValues.map((val) => ({ label: val, value: val }))}
        value={this.props.formData.brand || ''}
        onChange={(value) => this.props.updateFormData('brand', value)}
      />
    </CustomPanel>
  );
}

General.propTypes = {
  // ignoring eslint even though the prop is used
  // eslint-disable-next-line react/no-unused-prop-types
  adding: PropTypes.bool.isRequired,
  ccmConfig: PropTypes.shape().isRequired,
  disableCmpFields: PropTypes.bool.isRequired,
  formData: PropTypes.shape().isRequired,
  formErrors: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  stores: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateCountry: PropTypes.func.isRequired,
  updateFormData: PropTypes.func.isRequired,
  userIsOwner: PropTypes.bool.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default General;
