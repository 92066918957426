import countries from 'i18n-iso-countries';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { MSG_AUTH_EXPIRE } from '../../../constants/constants';
import { parseSLSandBrickworksErrors } from '../../../utils/formatting';
import { postStore, putStore } from '../../../utils/service-calls/sls';
import { getFilteredStores } from '../../../utils/sls-filter-handlers';
import { isUserAllowed, isUserReadOnly } from '../../../utils/tab-permissions';
import { Main } from '../generic';

import StoresForm from './StoresForm';

const config = require('../../../config')();
// have to do this to get country names in english
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

class StoresMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      country: { label: 'All Countries', value: '' },
      facilityType: { label: 'All Facility Types', value: '' },
      formOpen: this.props.detailOnly || false,
      offering: { label: 'All Offerings', value: '' },
      offeringFormOpen: false,
      postError: '',
      posting: false,
      postSuccess: '',
      putError: '',
      putSuccess: '',
      putting: false,
      region: this.props.initialRegion
        ? {
          label: this.props.initialRegion,
          value:
        this.props.initialRegion === 'All Regions'
          ? ''
          : this.props.initialRegion,
        }
        : { label: 'Select a Region', value: '' },
      regionSelected: false,
      search: '',
      serviceStatus: { label: 'All Service Status', value: '' },
      state: { label: 'All States', value: '' },
      storeService: { label: 'All Store Services', value: '' },
      storeStatus: { label: 'All Store Statuses', value: '' },
    };
  }

  clearFilters = () => this.setState({
    country: { label: 'All Countries', value: '' },
    offering: { label: 'All Offerings', value: '' },
    search: '',
    serviceStatus: { label: 'All Service Status', value: '' },
    state: { label: 'All States', value: '' },
    storeService: { label: 'All Store Services', value: '' },
    storeStatus: { label: 'All Store Statuses', value: '' },
  }, () => this.props.updateStores(getFilteredStores(this.state, this.props.loadedStores)));

  closeOrOpenForm = (formData = {}) => {
    this.props.updateFormData(formData);
    if (!this.state.formOpen) {
      // if the form is currently closed, open it
      this.setState({
        formOpen: true, postError: '', postSuccess: '', putError: '', putSuccess: '',
      });
    } else {
      // if the form is currently open, close it
      this.setState({ formOpen: false });
    }
    this.props.history.push('/storelocationservices/stores', {
      postError: '', postSuccess: '', putError: '', putSuccess: '',
    });
  }

  closeOrOpenOfferingForm = () => {
    if (!this.state.offeringFormOpen) {
      this.setState({
        offeringFormOpen: true,
        postError: '',
        postSuccess: '',
        putError: '',
        putSuccess: '',
      });
    } else {
      this.setState({
        offeringFormOpen: false,
      });
    }
    this.props.history.push('/storelocationservices/stores', {
      postError: '', postSuccess: '', putError: '', putSuccess: '',
    });
  }

  handleBrickworksError = (successMessage, errorMessage) => this.setState({ formOpen: false, putError: errorMessage, putSuccess: successMessage },
    () => this.handleFetch());

  handleError = (method, message) => this.setState({ formOpen: false, [method]: message },
    () => this.handleFetch());

  handleFetch = () => this.setState({ posting: false, putting: false, search: '' },
    () => this.props.history.push('/storelocationservices/stores', this.state));

  handleSuccess = (method, message) => this.setState({
    formOpen: false, [method]: message, offeringFormOpen: false, postError: '', putError: '',
  },
  () => this.handleFetch());

  post = (data) => this.setState({ posting: true },
    async () => postStore(this.props.userInfo.accessToken, data)
      .then(() => this.handleSuccess('postSuccess', 'Successfully added Store.'))
      .catch((error) => {
        const err = parseSLSandBrickworksErrors(error, true);
        if (err.timeout) return this.handleError('postError', 'POST timed out. Please refresh and try again.');
        if (err.brickworks) {
          return this.handleBrickworksError(
            'Successfully added Store.',
            `Brickworks write-back error ${err.statusCode}: ${err.message}`,
          );
        }
        if (err.message === MSG_AUTH_EXPIRE) {
          // don't refresh the page if the reason their call failed was because of old auth. they need to see the error first.
          return this.setState({ postError: `POST failed with error: ${err.message}`, posting: false });
        }
        return this.handleError('postError', `POST failed with error: ${err.message}`);
      }));

  put = (data) => this.setState({ putting: true },
    async () => {
      const dataToSend = data;
      if (!isUserAllowed(this.props.userInfo.groups, 'SLS.IP')
        || this.props.formData.region !== 'EUROPE_MIDDLE_EAST_AFRICA') {
        // if we are not editing IP attributes, don't send them in the update
        delete dataToSend.islandPacific;
      }
      return putStore(this.props.userInfo.accessToken, { new: dataToSend, old: this.props.formData })
        .then(() => this.handleSuccess('putSuccess', 'Successfully updated Store.'))
        .catch((error) => {
          const err = parseSLSandBrickworksErrors(error, true);
          if (err.timeout) return this.handleError('putError', 'PUT timed out. Please refresh and try again.');
          if (err.brickworks) {
            return this.handleBrickworksError(
              'Successfully updated Store.',
              `Brickworks write-back error ${err.statusCode}: ${err.message}`,
            );
          }
          if (err.message === MSG_AUTH_EXPIRE) {
            // don't refresh the page if the reason their call failed was because of old auth. they need to see the error first.
            return this.setState({ putError: `PUT failed with error: ${err.message}`, putting: false });
          }
          return this.handleError('putError', `PUT failed with error: ${err.message}`);
        });
    });

  updateFilter = (filterType, value) => this.setState({ [filterType]: { label: value.label, value: value.value } },
    () => this.props.updateStores(getFilteredStores(this.state, this.props.loadedStores)));

  updateSearch = (search) => this.setState({ search },
    () => this.props.updateStores(getFilteredStores(this.state, this.props.loadedStores)));

  render = () => (
    <Main
      changeSearch={(value) => this.updateSearch(value)}
      clearFilters={this.clearFilters}
      closeOrOpenForm={(data) => this.closeOrOpenForm(data)}
      closeOrOpenOfferingForm={this.closeOrOpenOfferingForm}
      columns={[
        {
          accessor: (d) => (d.storeNumber),
          Cell: (d) => <a href={`${config.host}/storelocationservices/stores/detail/${d.original.id}`} style={{ display: 'block' }}>{d.original.storeNumber}</a>,
          Header: 'Store #',
          id: 'Store #',
          style: { fontWeight: 'bold' },
          width: 100,
        },
        {
          accessor: (d) => (d.name),
          Cell: (d) => <a href={`${config.host}/storelocationservices/stores/detail/${d.original.id}`} style={{ display: 'block' }}>{d.original.name}</a>,
          Header: 'Store Name',
          id: 'Store Name',
          style: { fontWeight: 'bold' },
        },
        {
          accessor: (d) => `${d.company}${d.facilityType ? ` - ${d.facilityType}` : ''}`,
          Cell: (d) => <a href={`${config.host}/storelocationservices/stores/detail/${d.original.id}`} style={{ display: 'block' }}>{`${d.original.company}${d.original.facilityType ? ` - ${d.original.facilityType}` : ''}`}</a>,
          Header: 'Company - Facility Type',
          id: 'Company - Facility Type',
        },
        {
          accessor: (d) => (d.businessConcept),
          Cell: (d) => <a href={`${config.host}/storelocationservices/stores/detail/${d.original.id}`} style={{ display: 'block' }}>{d.original.businessConcept}</a>,
          Header: 'Business Concept',
          id: 'Business Concept',
          width: 200,
        },
        {
          accessor: (d) => (d.address.country),
          Cell: (d) => <a href={`${config.host}/storelocationservices/stores/detail/${d.original.id}`} style={{ display: 'block' }}>{d.original.address.country}</a>,
          Header: 'Country',
          id: 'Country',
          width: 150,
        },
        {
          accessor: (d) => (d.region),
          Cell: (d) => <a href={`${config.host}/storelocationservices/stores/detail/${d.original.id}`} style={{ display: 'block' }}>{d.original.region}</a>,
          Header: 'Region',
          id: 'Region',
        },
      ]}
      country={this.state.country}
      facilities={getFilteredStores(this.state, this.props.stores)}
      facilitiesAll={this.props.loadedStores}
      facilityType="Stores"
      fetched={this.props.loadedStores.length > 0}
      filters={[
        {
          accessor: 'region',
          changeValue: (value) => {
            this.setState({ regionSelected: true });
            this.updateFilter('region', value);
            this.props.loadStoresForRegion(value);
            localStorage.setItem('ros-stores-region', value.label);
          },
          getLabel: (code) => code,
          id: 'regionFilter',
          isDisabled: false,
          label: 'All Regions',
          option: this.state.region,
          required: !this.state.regionSelected && !this.props.initialRegion,
        },
        {
          accessor: 'address.country',
          changeValue: (value) => this.updateFilter('country', value),
          // TODO: remove Türkiye hardcoding when dependent module releases a version with the new name
          getLabel: (code) => (code === 'TUR' ? 'Türkiye' : countries.getName(code, 'en')),
          id: 'countryFilter',
          isDisabled: this.props.loadedStores.length === 0,
          label: 'All Countries',
          option: this.state.country,
        },
        {
          accessor: 'address.state',
          changeValue: (value) => this.updateFilter('state', value),
          getLabel: (code) => code,
          id: 'stateFilter',
          isDisabled: this.props.loadedStores.length === 0,
          label: 'All States',
          option: this.state.state,
        },
        {
          accessor: 'facilityType',
          changeValue: (value) => this.updateFilter('facilityType', value),
          getLabel: (code) => code,
          id: 'facilityTypeFilter',
          isDisabled: this.props.loadedStores.length === 0,
          label: 'All Facility Types',
          option: this.state.facilityType,
        },
        {
          accessor: (store) => (store.offerings ? store.offerings.map((offer) => offer.name) : []),
          changeValue: (value) => this.updateFilter('offering', value),
          getLabel: (code) => code,
          id: 'offeringFilter',
          isDisabled: this.props.loadedStores.length === 0,
          label: 'All Offerings',
          option: this.state.offering,
        },
        {
          accessor: (store) => (store.storeServices ? store.storeServices.map((service) => service.serviceGroup) : []),
          changeValue: (value) => this.updateFilter('storeService', value),
          getLabel: (code) => code,
          id: 'storeServiceFilter',
          isDisabled: this.props.loadedStores.length === 0,
          label: 'All Services',
          option: this.state.storeService,
        },
        {
          accessor: 'serviceStatus',
          changeValue: (value) => this.updateFilter('serviceStatus', value),
          disabled: this.state.storeService.value === '',
          getLabel: (code) => code,
          id: 'serviceStatusFilter',
          label: 'All Service Status',
          option: this.state.serviceStatus,
        },
        {
          accessor: 'storeStatus',
          changeValue: (value) => this.updateFilter('storeStatus', value),
          getLabel: (code) => code,
          id: 'storeStatusFilter',
          isDisabled: this.props.loadedStores.length === 0,
          label: 'All Store Statuses',
          option: this.state.storeStatus,
        },
      ]}
      form={(
        <StoresForm
          adding={this.props.formData.id === undefined}
          ccmConfig={this.props.ccmConfig}
          formData={this.props.formData}
          history={this.props.history}
          offeringsOptions={this.props.storeOfferings}
          showCMP={this.props.userInfo.groups.includes('App.RBH.AdminUI.Users.SLS.CMP')}
          showIP={isUserAllowed(this.props.userInfo.groups, 'SLS.IP')
            && this.props.formData.region === 'EUROPE_MIDDLE_EAST_AFRICA'}
          showJE={isUserAllowed(this.props.userInfo.groups, 'SLS.JE')}
          stores={this.props.loadedStores}
          submit={this.props.formData.id === undefined
            ? (data, errs) => this.post(data, errs)
            : (data, errs) => this.put(data, errs)}
          userGroups={this.props.userInfo.groups}
          userIsOwner={isUserAllowed(this.props.userInfo.groups, 'SLS.Owner')}
          userIsReadOnly={isUserReadOnly(this.props.userInfo.groups, ['SLS.ReadOnly', 'SLS'])}
          userToken={this.props.userInfo.accessToken}
          username={this.props.userInfo.username}
        />
      )}
      formOpen={this.state.formOpen}
      getError={this.props.getError}
      getting={this.props.getting}
      offeringFormOpen={this.state.offeringFormOpen}
      post={(data) => this.submit(data)}
      postError={this.props.history.location.state?.postError || this.state.postError || ''}
      postSuccess={this.props.history.location.state?.postSuccess || ''}
      posting={this.state.posting}
      put={(data) => this.submit(data)}
      putError={this.props.history.location.state?.putError || this.state.putError || ''}
      putSuccess={this.props.history.location.state?.putSuccess || ''}
      putting={this.state.putting}
      region={this.state.region}
      search={this.state.search}
      userEmail={this.props.userInfo.email}
      userGroups={this.props.userInfo.groups}
      userIsOwner={isUserAllowed(this.props.userInfo.groups, 'SLS.Owner')}
      userToken={this.props.userInfo.accessToken}
      username={this.props.userInfo.username}
    />
  )
}

StoresMain.propTypes = {
  ccmConfig: PropTypes.shape().isRequired,
  detailOnly: PropTypes.bool.isRequired,
  formData: PropTypes.shape({
    id: PropTypes.string,
    region: PropTypes.string,
  }).isRequired,
  getError: PropTypes.string.isRequired,
  getting: PropTypes.bool.isRequired,
  history: PropTypes.shape().isRequired,
  initialRegion: PropTypes.string.isRequired,
  loadedStores: PropTypes.arrayOf(PropTypes.shape).isRequired,
  loadStoresForRegion: PropTypes.func.isRequired,
  storeOfferings: PropTypes.arrayOf(PropTypes.shape).isRequired,
  stores: PropTypes.arrayOf(PropTypes.shape).isRequired,
  updateFormData: PropTypes.func.isRequired,
  updateStores: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    accessToken: PropTypes.string,
    email: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.string),
    username: PropTypes.string,
  }).isRequired,
};

export default withRouter(StoresMain);
