import { Close, Delete } from '@nike/nike-design-system-icons';
import PropTypes from 'prop-types';
import React from 'react';
import './selected.css';

const Selected = ({
 input, update, search, onUpdate
}) => {
    const storesByCountries = (data) => {
      const groups = data.reduce((group, item) => {
        const { country } = item;
        group[country] = group[country] ?? [];
        group[country].push(item);
        return group;
        }, {});

    const groupByCountry = Object.entries(groups).sort().map(([key, value]) => ({
        country: key,
        stores: value
      }));
    return groupByCountry;
    };

    const deleteByLabel = (label) => {
      const updatedData = {
        ...input,
        data: input.data.filter((selectedConfig) => selectedConfig.label !== label)
      };
      onUpdate(updatedData);
    };

    const deleteByCountry = (country) => {
      const updatedData = {
        ...input,
        data: input.data.filter((selectedConfig) => selectedConfig.country !== country)
      };
      onUpdate(updatedData);
    };

    const deleteByStore = (country, store) => {
        const updatedData = {
          ...input,
          data: input.data.filter((selectedConfig) => !(selectedConfig.country === country && selectedConfig.label === store.label))
        };
        onUpdate(updatedData);
    };

    const getSearchedConfigs = () => {
      if (search !== '') {
        const regExp = new RegExp(String(search).trim(), 'i');
        if (input.scopeType === 'STORE') {
          return input.data.filter((item) => regExp.test(String(item.country)) || regExp.test(String(item.label)));
        } else {
          return input.data.filter((item) => regExp.test(String(item.label)));
        }
      } else {
        return input.data;
      }
    };

    const resetData = () => {
      const updatedData = {
        ...input,
        data: []
      };
      onUpdate(updatedData);
    };

    return (
      <article className="ncss-col-sm-12 va-sm-t ta-sm-l">
        <br />
        <article className="ncss-col-sm-12 va-sm-t ta-sm-r">
          {update && <button className="ncss-btn-primary-dark border ta-sm-r" type="button" onClick={resetData}>Reset</button>}
        </article>
        <article className="ncss-col-sm-12 va-sm-t ta-sm-l pt4-sm">
          {input.scopeType === 'STORE' && storesByCountries(getSearchedConfigs()).map((data) => (
            <div key={data.country} className="country-section">
              <div className="country-header pb2-sm">
                <h3 className="headline-4">{`${data.country} - ${data.stores.length}`}</h3>
                {update && <Delete name="Delete" size="m" onClick={() => deleteByCountry(data.country)} />}
              </div>

              <hr className=".u-bold" />

              <div className="stores-container">
                {data.stores.map((store) => (
                  <div key={store.label} className="ncss-btn-primary-dark border bg-black pt4-sm pr4-sm pb4-sm pl4-sm pt2-md pb2-md">
                    <span>{`${store.label}   `}</span>
                    {update && <Close name="Close" size="m" style={{ flexDirection: 'row', outline: 'none', verticalAlign: 'middle' }} onClick={() => deleteByStore(data.country, store)} />}
                  </div>
              ))}
              </div>
            </div>
        ))}
          {input.scopeType !== 'STORE'
          && (
          <div className="stores-container">
            {getSearchedConfigs().map((item) => (
              <div key={item.label} className="ncss-btn-primary-dark border bg-black pt4-sm pr4-sm pb4-sm pl4-sm pt2-md pb2-md">
                <span>{`${item.label}    `}</span>
                {update && <Close name="Close" size="s" style={{ flexDirection: 'row', outline: 'none', verticalAlign: 'middle' }} onClick={() => deleteByLabel(item.label)} />}
              </div>
              ))}
          </div>
        )}
        </article>
      </article>
    );
};

Selected.defaultProps = {
  search: '',
  update: true
};

Selected.propTypes = {
  input: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired
    })),
    scopeType: PropTypes.string.isRequired
}).isRequired,
  onUpdate: PropTypes.func.isRequired,
  search: PropTypes.string,
  update: PropTypes.bool,
};

export default Selected;
